import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { AuthContext } from "src/context/Auth";

import { ACTIVE_NETWORK, NetworkContextName } from "src/constants";
import { useWeb3React } from "@web3-react/core";
import Apiconfig from "../../../config/APIConfig";
import { toast } from "react-toastify";
import axios from "axios";
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  makeStyles,
  IconButton,
  Badge,
  DialogContent,
  DialogTitle,
  Dialog,
  DialogContentText,
  Avatar,
  Typography,
} from "@material-ui/core";
import { UserContext } from "src/context/User";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SwipeableTemporaryDrawer from "./RightDrawer";
import { useHistory } from "react-router-dom";
import Logo from "src/component/Logo";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.paper,
  },
  toolbar: {
    height: 80,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  avatar: {
    width: 50,
    height: 50,
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const history = useHistory();
  const user = useContext(UserContext);

  const [userdata, setuserdata] = React.useState([]);
  const { account, library, chainId } = useWeb3React();
  const accessToken = window.localStorage.getItem("creatturAccessToken");

  const profileapi = async () => {
    await axios
      .get(Apiconfig.profile, {
        headers: {
          token: `${accessToken}`,
        },
      })
      .then(async (res) => {
        if (res.data.response_code === 200) {
          setuserdata(res.data.result);
          console.log("hjghj)))))0000", res.data.result);
        } else if (res.data.response_code === 404) {
          console.log("/404");
        } else {
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    profileapi();
  }, []);
  return (
    <>
      <Logo
        width="70"
        onClick={() => history.push("/dashboard")}
        style={{ cursor: "pointer", paddingLeft: 16 }}
      />
      <Box flexGrow={1} />
      <Box>
        {/* <Typography style={{ color: "#000" }}>
              
            </Typography> */}
        {!account && (
          <Button
            style={{
              background: "#252d47",
              borderRadius: "21px",
              color: "#fff",
              border: "2px solid rgb(37 45 71)",
            }}
            variant="outlined"
            aria-label="small outlined button group"
            onClick={() =>
              user.connectWallet(toast.success("Successfully connected"))
            }
          >
            Connect Wallet
          </Button>
        )}
        {account && (
          <Button
            style={{
              background:
                "linear-gradient(124deg, #2f5995cf 18.76%, #151d2ade 43.13%, #0058f1a6 96.83%)",
              borderRadius: "21px",
              color: "#fff",
              border: "2px solid rgb(37 45 71)",
            }}
            variant="outlined"
            aria-label="small outlined button group"
            onClick={() =>
              user.dicconectWalletFun(toast.error("Successfully disconnected"))
            }
          >
            {" "}
            Disconnect{" "}
            {account &&
              `${account.substring(0, 4)}...${account.substring(
                account.length - 4,
                account.length
              )}`}
          </Button>
        )}
      </Box>
      {/* <SwipeableTemporaryDrawer /> */}
      {/* <Avatar
        src={
          auth.userData.userDetail && auth.userData.userDetail.imageUrl !== null
            ? auth.userData.userDetail.imageUrl
            : "/images/user.png"
        }
        className={classes.avatar}
      /> */}
      {/* <Dialog
        open={account && chainId !== ACTIVE_NETWORK}
        minWidth="md"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Netwok Change Error"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box ml={3} pb={3}>
              <Typography>Please switch to {NetworkContextName}</Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog> */}
    </>
  );
}
