/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import { useLocation, matchPath, Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  ListSubheader,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

import { BsList } from "react-icons/bs";
// import DashboardIcon from '@material-ui/icons';
// import AccessAlarmIcon  from '@material-ui/icons/AccessAlarm';
import {
  FaSignOutAlt,
  FaUserCircle,
  FaUser,
  FaRegEdit,
} from "react-icons/fa";
// import {GrTransaction} from 'react-icons/gr';
import { AiFillSetting } from "react-icons/ai";
import { MdAttachMoney } from "react-icons/md";
import { MdAssessment } from "react-icons/md";
import { PieChart as PieChartIcon } from "react-feather";
import { RiDashboardFill } from "react-icons/ri";
import NavItem from "./NavItem";
const sections = [
  {
    subheader: "MENU",
    items: [
      {
        title: "Dashboard",
        icon: FaUserCircle,
        href: "/dashboard",
      },
    ],
  },
  {
    subheader: " Sub Admin Management",
    items: [
      {
        title: "Sub Admin",
        icon: FaUser,
        href: "",
        items: [
          {
            title: "Add Sub Admin",
            icon: "",
            href: "/add-sub-admin",
          },
          {
            title: "List Sub Admin",
            icon: "",
            href: "/sub-admin-list",
          },
        ],
      },
    ],
  },
  {
    subheader: "USER",
    items: [
      {
        title: "User",
        icon: FaUser,
        href: "",
        items: [
          {
            title: "List User",
            icon: "",
            href: "/user-list",
          },
          // {
          //   title: "List Sub Admin",
          //   icon: "",
          //   href: "/sub-admin-list",
          // },
          // {
          //   title: "Report user",
          //   icon: "",
          //   href: "/report-user",
          // },
        ],
      },
    ],
  },

  {
    subheader: "NFT’s List",
    items: [
      {
        title: "NFT’s List",
        icon: BsList,
        href: "",

        items: [
          {
            title: "NFT's List",
            icon: "",
            href: "/nft-list",
          },
          {
            // title: "Report user",
            // icon:'',
            // href: "/Report-User",

            title: "Placed Order List",
            icon: "",
            href: "/place-order-list",
          },
          {
            title: "Canceled Order List",
            icon: "",
            href: "/cancelorderlist",
          },
        ],
      },
    ],
  },
  
 
  {
    subheader: "Static Management",
    items: [
      {
        title: "Static Management",
        icon: FaRegEdit,
        href: "",
        items: [
          {
            title: "Static Content",
            icon: "",
            href: "/Termsof-Use",
          },
          {
            title: "Faq",
            icon: "",
            href: "/faq",
          },
          {
            title: "Social Links",
            icon: "",
            href: "/Social-Links",
          },
          //
          // {
          //   title: "Reach us",
          //   icon: "",
          //   href: "/reachus",
          // },
          // {
          //   title: "Left Side Data",
          //   icon: "",
          //   href: "/left-side-data",
          // },
        ],
      },
    ],
  },
  {
    items: [
      // {
      //   title: "Logout",
      //   icon: FaSignOutAlt,
      //   href: "/login",
      // },
    ],
  },
];

function renderNavItems({ items, ...props }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...props }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0,
  isNested = false,
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          isNested: true,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={
          <div style={{ width: isNested ? "auto" : 100, textAlign: "left" }}>
            {item.title}
          </div>
        }
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: theme.palette.primary.main,
  },
  desktopDrawer: {
    width: 256,
    top: 80,
    height: "calc(100% - 80px)",
    background: theme.palette.primary.main,
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  lastbtn: {
    display: "flex-start",
    color: "#969ba1",
    "&:hover": {
      color: "#fff",
      //     background: "linear-gradient(124deg, rgba(47, 89, 149, 0.81) 18.76%, rgba(21, 29, 42, 0.87) 43.13%, rgba(0, 88, 241, 0.65) 96.83%)",

    },
  },
  butm: {
    display: "flex",
    justifyContent: "center",
    // "&:hover": {
    //       background: "linear-gradient(124deg, rgba(47, 89, 149, 0.81) 18.76%, rgba(21, 29, 42, 0.87) 43.13%, rgba(0, 88, 241, 0.65) 96.83%)",

    // },
    paddingBottom: "30px",
  },
  butm1: {
    backgroundColor: "#252d47",
    color: "#fff",
    "&:hover": {
          background: "linear-gradient(124deg, rgba(47, 89, 149, 0.81) 18.76%, rgba(21, 29, 42, 0.87) 43.13%, rgba(0, 88, 241, 0.65) 96.83%)",

    },
  },
  butm2: {
    backgroundColor: "#252d47",
    color: "#fff",
    "&:hover": {
          background: "linear-gradient(124deg, rgba(47, 89, 149, 0.81) 18.76%, rgba(21, 29, 42, 0.87) 43.13%, rgba(0, 88, 241, 0.65) 96.83%)",

    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box py={4}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader
                  disableGutters
                  disableSticky
                  style={{
                    paddingLeft: "15px",
                    fontWeight: "400",
                    color: "white",
                  }}
                >
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
          <Box
            style={{
              paddingLeft: "19px",
              fontWeight: "400",
              alignItems: "center",
              color: "white",
            }}
          >
            <Button
              // fullWidth
              className={classes.lastbtn}
              startIcon={<FaSignOutAlt />}
              onClick={() => {
                setOpen(true);
              }}
              // style={{ color: "#969ba1" }}
            >
              Logout
            </Button>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ color: "#52565c", fontSize: "18px",fontWeight:"400" }}
          >
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.butm}>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.butm1}
            to="/"
            component={Link}
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.butm2}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
