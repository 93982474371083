const url = "https://node-nft-marketplace-vincent.mobiloitte.org";
// const url = "https://node.iccc.co";

// const url = "http://172.16.1.172:1859";

///api/v1/admin/viewUser
const Apiconfig = {
  login: `${url}/api/v1/admin/login`,
  profile: `${url}/api/v1/admin/getProfile`,
  editprofile: `${url}/api/v1/admin/editProfile`,
  userlist: `${url}/api/v1/admin/listUser`,
  viewuser: `${url}/api/v1/admin/viewUser`,
  staticdata: `${url}/api/v1/static/staticList`,
  staticedit: `${url}/api/v1/static/editStaticPage`,
  addSocialLink: `${url}/api/v1/static/addSocialLink`,
  getparticularLink: `${url}/api/v1/static/getparticularLink`,
  getLink: `${url}/api/v1/static/getLink`,
  editLinkContent: `${url}/api/v1/static/editLinkContent`,

  // sidedatalist: `${url}/api/v1/sideData/sideDataList`,
  viewsidedata: `${url}/api/v1/sideData/viewSideData`,
  editsidedata: `${url}/api/v1/sideData/editSideData`,

  contactdata: `${url}/api/v1/admin/contactUs`,
  viewlinkpage: `${url}/api/v1/link/viewLinkPage`,
  linkupdate: `${url}/api/v1/link/linkDataUpdate`,

  feautueduser: `${url}/api/v1/admin/featureUserList`,
  listuser: `${url}/api/v1/admin/listUser`,
  category: `${url}/api/v1/admin/listCategory`,
  addcategory: `${url}/api/v1/admin/addCategory`,
  viewcategory: `${url}/api/v1/admin/viewCategory`,
  editcategory: `${url}/api/v1/admin/editCategory`,
  editreachus: `${url}/api/v1/admin/editContactUs`,
  feauturecollectionlist: `${url}/api/v1/admin/featureCollectionList`,
  feautureunfeauturecollection: `${url}/api/v1/admin/feautureUnfeautureCollection`,
  feautureunfeautureuser: `${url}/api/v1/admin/featureUnfeatureUser`,
  viewfeauturecollection: `${url}/api/v1/admin/viewFeatureCollection`,
  viewfeautureuser: `${url}/api/v1/admin/viewFeatureUser`,
  deletecategory: `${url}/api/v1/admin/deleteCategory`,
  dashboard: `${url}/api/v1/user/dashboard`,
  cancelPlaceOrder: `${url}/api/v1/admin/cancelPlaceOrder`,
  blockUnblockUser: `${url}/api/v1/admin/blockUnblockUser`,
  placeOrderList: `${url}/api/v1/admin/placeOrderList`,
  listNFT: `${url}/api/v1/admin/listNFT`,
  viewNft: `${url}/api/v1/admin/viewNft`,
  placeOrderListById: `${url}/api/v1/nft/placeOrderListById/`,
  cancelPlaceOrder: `${url}/api/v1/admin/cancelPlaceOrder`,
  viewCancelOrder: `${url}/api/v1/admin/viewCancelOrder`,
  transactionList: `${url}/api/v1/admin/transactionList`,
  viewnftHistoryTransaction: `${url}/api/v1/admin/viewnftHistoryTransaction`,
  nftTransactionList: `${url}/api/v1/admin/nftTransactionList`,
  viewNftTransaction: `${url}/api/v1/admin/viewNftTransaction/`,
  placeOrderList: `${url}/api/v1/admin/placeOrderList/`,
  addSubAdmin: `${url}/api/v1/admin/addSubAdmin`,
  cancelPlaceOrderList: `${url}/api/v1/admin/cancelPlaceOrderList`,
  viewCancelOrder: `${url}/api/v1/admin/viewCancelOrder`,
  viewSubAdmin: `${url}/api/v1/admin/viewSubAdmin`,
  forgotPassword: `${url}/api/v1/admin/forgotPassword`,
  otpVerify: `${url}/api/v1/admin/otpVerify`,
  resetPassword: `${url}/api/v1/admin/resetPassword`,
  resendOTP: `${url}/api/v1/admin/resendOTP`,
  listSubAdmin: `${url}/api/v1/admin/listSubAdmin`,
  deleteUser: `${url}/api/v1/admin/deleteUser`,
  deleteSubAdmin: `${url}/api/v1/admin/deleteSubAdmin`,
  listStaticContent: `${url}/api/v1/static/listStaticContent`,
  getStaticContent: `${url}/api/v1/static/getStaticContent`,
  addStaticContent: `${url}/api/v1/static/addStaticContent`,
  editStaticContent: `${url}/api/v1/static/editStaticContent`,
  deleteLinkContent: `${url}/api/v1/static/deleteLinkContent`,

  //faq section API

  listFAQ: `${url}/api/v1/faq/listFAQ`,
  addFAQ: `${url}/api/v1/faq/addFAQ`,
  editFAQ: `${url}/api/v1/faq/editFAQ`,
  getFAQ: `${url}/api/v1/faq/getFAQ`,
  deleteFAQ: `${url}/api/v1/faq/deleteFAQ`,
};

export default Apiconfig;
